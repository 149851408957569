import { graphql, Link, PageProps } from "gatsby"
import React, { ReactElement } from "react"
import { FreeAccount, GetStarted } from "../components/HomePage/Banner"
import NewsAndUpdates from "../components/HomePage/NewsAndUpdates"
import Subscribe from "../components/HomePage/Subscribe"
import Seo from "../components/seo"
import DefaultLayout from "../templates/DefaultLayout"
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="text-lg md:text-2xl mt-5 max-w-[600px] pb-6">{children}</p>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="text-3xl md:text-5xl font-bold pb-4">{children}</h1>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal text-base lg:text-lg ml-10">{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc text-base lg:text-lg ml-10">{children}</ul>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link to={node.data.uri} className="text-br-primary-blue font-bold">
        {children}
      </Link>
    ),
  },
}

export default function ThankYou(
  props: PageProps<{
    allContentfulThankYou: {
      nodes: [
        {
          title: string
          body: {
            raw: string
          }
        }
      ]
    }
  }>
): ReactElement {
  const document = props.data?.allContentfulThankYou.nodes[0].body.raw
  return (
    <DefaultLayout>
      <Seo title="Thank you" />
      <div className="min-h-[70vh] flex items-center justify-center flex-col text-br-primary-blue text-center px-4">
        {documentToReactComponents(JSON.parse(document), options)}
      </div>
      <NewsAndUpdates />
      <div className="py-14 flex items-center justify-center flex-col text-br-primary-blue text-center px-4">
        <h2 className="text-2xl md:text-4xl  pb-4">Why Wait?</h2>

        <p className="text-lg md:text-2xl mt-5 max-w-[600px] pb-6">
          With built in tutorials, <br /> you can make and account and get
          started today!
        </p>
        <GetStarted />
        <FreeAccount />
      </div>
    </DefaultLayout>
  )
}

export const ThankYouQuery = graphql`
  query ThankYouQuery {
    allContentfulThankYou {
      nodes {
        body {
          raw
        }
      }
    }
  }
`
